import { Box, Button, Checkbox, Stack } from '@mui/material'
import { cancelReceivedComplete, setReceivedComplete } from '../../api/order'
import { getFee, getDate, priceWrapper } from '../../utils'

import styled from 'styled-components'
import { OUTER_SHIPPING_STATUS } from '../../pages/transaction/sellList'
import { valueToName } from './select-btn'
import { getInvoice } from '../../api/dhl'

const heads = [
  '',
  '판매일',
  '판매자',
  '주문번호',
  '상품주문번호',
  '상품명',
  '구매자',
  '국가',
  '해외판매가',
  '개수',
  '결제가',
  '정산금액',
  '정산상태',
  '국내배송상태',
  '해외배송상태',
  '반품여부',
  '디바이스',
]

const INNER_SHIPPING_STATUS_TABLE = {
  REQUEST_SENT: '발송요청',
  SENT_COMPLETE: '발송완료',
  SHIPPING: '배송중',
  ARRIVED_CENTER: '센터도착',
  RECEIVED_COMPLETE: '입고완료',
  RETURN: '반송',
}

const OUTER_SHIPPING_STATUS_TABLE = {
  IN_TRANSIT: '국내 배송중',
  PENDING_EXPORT: '출고대기',
  EXPORT_COMPLETE: '출고완료',
  SHIPPING: '해외 배송중',
  DELIVERY_COMPLETE: '배송완료',
}

const SellTable = ({
  orderItems,
  checked,
  setChecked,
  handleOpen,
  setModify,
  setModal,
  closeStockedModal,
  setOrderItems,
  clickCancelStockedModal,
  cancelId,
  openCancelInvoiceModal,
}) => {
  const onClickCheckBtn = (item) => {
    if (checked.map((o) => o.id).includes(item.id)) {
      const idx = checked.findIndex((o) => o.id === item.id)
      checked.splice(idx, 1)
    } else {
      checked.push(item)
    }
    setChecked([...checked])
  }

  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {orderItems.map((item, index) => {
          const {
            order: { orderNumber, deviceType },
            product: { name },
            seller,
            user: { nickname },
            orderItemNumber,
            createdAt,
            orderPrice,
            couponUsed,
            creditUsed,
            domesticPrice,
            innerShippingStatus,
            outerShippingStatus,
            isTransaction,
            isReturn,
            innerShippingCompany,
            innerInvoiceNumber,
            orderCount,
            countryCode,
          } = item

          const customerOrderPrice =
            Math.round((orderPrice - creditUsed - couponUsed) * 100) / 100

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    checked={checked.map((o) => o.id).includes(item.id)}
                    onClick={(e) => {
                      onClickCheckBtn(item)
                    }}
                  />
                </Box>
              </Td>
              <Td>{getDate(createdAt)}</Td>
              <Td>{seller.name}</Td>
              <Td>{orderNumber}</Td>
              <Td>{orderItemNumber}</Td>
              <Td width="200px">
                <PBox>{name}</PBox>
              </Td>
              <Td>{nickname}</Td>
              <Td>{countryCode}</Td>
              <Td>{orderPrice}</Td>
              <Td>{orderCount}</Td>
              <Td>{customerOrderPrice}</Td>
              <Td>
                {priceWrapper(
                  Math.floor(domesticPrice * (1 - getFee(seller.grade))) *
                    orderCount
                )}
              </Td>
              <Td>{isTransaction ? 'Y' : 'N'}</Td>
              <Td>
                {getInnerShippingTap(
                  innerShippingStatus,
                  innerShippingCompany,
                  innerInvoiceNumber,
                  outerShippingStatus,
                  () => {
                    setModal({
                      on: true,
                      mainTxt: '입고처리 하시겠습니까?',
                      leftTxt: '입고',
                      rightTxt: '취소',
                      leftBg: 'black',
                      rightBg: 'red',
                      clickLeft: async () => {
                        if (item.innerShippingStatus !== 'SENT_COMPLETE') {
                          alert(
                            '발송완료 상태에서만 입고처리를 할 수 있습니다.'
                          )
                          closeStockedModal()
                          return
                        }

                        const { success } = await setReceivedComplete([item.id])

                        if (!success) {
                          return
                        }

                        item.innerShippingStatus = 'RECEIVED_COMPLETE'
                        item.outerShippingStatus = 'PENDING_EXPORT'

                        setOrderItems([...orderItems])
                        setChecked([])
                        closeStockedModal()
                      },
                    })
                  },
                  () => {
                    setModal({
                      on: true,
                      mainTxt: '입고취소 하시겠습니까?',
                      leftTxt: '입고취소',
                      rightTxt: '취소',
                      leftBg: 'black',
                      rightBg: 'red',
                      clickLeft: async () => {
                        if (
                          item.innerShippingStatus !== 'RECEIVED_COMPLETE' ||
                          item.outerShippingStatus !== 'PENDING_EXPORT'
                        ) {
                          alert(
                            '입고완료 상태와 출고 대기 상태에서만 입고취소 처리를 할 수 있습니다.'
                          )
                          closeStockedModal()
                          return
                        }

                        const { success } = await cancelReceivedComplete([
                          item.id,
                        ])

                        if (!success) {
                          return
                        }

                        item.innerShippingStatus = 'SENT_COMPLETE'
                        item.outerShippingStatus = 'IN_TRANSIT'

                        setOrderItems([...orderItems])
                        closeStockedModal()
                      },
                    })
                  }
                )}
              </Td>
              <Td>
                {getOuterShippingTap(item, cancelId, openCancelInvoiceModal)}
              </Td>
              <Td>{isReturn ? 'Y' : 'N'}</Td>
              <Td>{deviceType}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default SellTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const PBox = styled('div')({
  width: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const P = styled('p')({
  cursor: 'pointer',
  display: 'inline',
  borderBottom: '1px solid',
})

const getInvoiceState = (
  invoiceNumber,
  innerShippingStatus,
  onClickSendPaymentInput,
  item
) => {
  if (innerShippingStatus !== 'RECEIVED_COMPLETE') {
    return <></>
  }

  const onClick = () => {
    onClickSendPaymentInput(item, invoiceNumber)
  }

  return invoiceNumber ? (
    <P onClick={onClick}>발송정보 변경</P>
  ) : (
    <P onClick={onClick} style={{ color: '#003bff' }}>
      발송정보 입력하기
    </P>
  )
}

const getInnerShippingTap = (
  innerShippingStatus,
  innerShippingCompany,
  innerInvoiceNumber,
  outerShippingStatus,
  clickStockedModal,
  clickCancelStockedModal
) => {
  const name = INNER_SHIPPING_STATUS_TABLE[innerShippingStatus]

  const Btn = (text, onClick) => (
    <p
      style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
      onClick={onClick}
    >
      {text}
    </p>
  )

  if (innerShippingStatus === 'SENT_COMPLETE') {
    return (
      <>
        <p>{name}</p>
        <Box display="flex" justifyContent="center" gap={3}>
          <a
            href={`https://search.naver.com/search.naver?where=nexearch&query=${valueToName(
              innerShippingCompany
            )}+${innerInvoiceNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            배송상태
          </a>

          {Btn('입고완료처리', clickStockedModal)}
        </Box>
      </>
    )
  } else if (
    innerShippingStatus === 'RECEIVED_COMPLETE' &&
    outerShippingStatus !== 'EXPORT_COMPLETE'
  ) {
    return (
      <>
        <p>{name}</p>

        {Btn('입고완료 취소', clickCancelStockedModal)}
      </>
    )
  }

  return <p>{name}</p>
}

const getOuterShippingTap = (item, cancelId, openCancelInvoiceModal) => {
  const { outerShippingStatus, outerInvoiceNumber, shipment } = item

  const name = OUTER_SHIPPING_STATUS_TABLE[outerShippingStatus]

  if (outerShippingStatus === 'IN_TRANSIT') {
    return <p>{name}</p>
  }

  if (outerShippingStatus === OUTER_SHIPPING_STATUS[3].value) {
    const openUrl = async () => {
      const labelKey = shipment.labelUrl.split('.com/')[1]
      const list = await getInvoice([labelKey])

      for (const item of list) {
        const { Body } = item
        const bytes = new Uint8Array(Body.data)

        const url = URL.createObjectURL(
          new Blob([bytes], { type: 'application/pdf' })
        )

        window.open(url)
      }
    }

    return (
      <Stack direction="column" alignItems="center">
        <p>{name}</p>
        <Stack direction="row" gap="5px">
          <Button
            sx={{ color: '#00a52d', padding: 0, textDecoration: 'underline' }}
            onClick={openUrl}
          >
            송장 확인
          </Button>
          <Button
            sx={{ color: '#ff0000', padding: 0, textDecoration: 'underline' }}
            onClick={() => {
              cancelId.current = item.id
              openCancelInvoiceModal()
            }}
          >
            송장 발송취소
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      <p>{name}</p>
      <a
        href={`https://search.naver.com/search.naver?where=nexearch&query=DHL+${outerInvoiceNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        배송상태
      </a>
    </>
  )
}
