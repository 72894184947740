import { Divider } from '@mui/material'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import Navigation from '../../components/common/navigation'
import { CheckSnackbar } from '../../components/Snackbar'
import DragDropItems from '../../components/product/DragDropItems'
import RegisterProduct from './registerProduct'
import {
  createProductSet,
  findProductSet,
  getProductSets,
  setOrderNumberForProductSet,
  updateProductSet,
} from '../../api/productSet'
import ProductSetListTable from '../../components/promotion/ProductSetListTable'

const init = { id: null, title: '', products: [] }

const ProductAdminPage = () => {
  const [onRegister, setOnRegister] = useState(false)
  const [state, setState] = useState(init)

  const [option, setOption] = useState({
    count: 0,
    page: 1,
  })

  const [productSets, setProductSets] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [onRegister])

  useEffect(() => {
    ;(async () => {
      const data = await getProductSets(option)
      setProductSets(data.data)
      setOption((prev) => ({ ...prev, count: data.count }))
    })()
  }, [option.page])

  const createPSet = async () => {
    if (!state.title || state.products.length === 0) {
      alert('product 최소 1개 이상 혹은 title 필수')
      return
    }
    const { success, data } = await createProductSet({
      title: state.title,
      productIds: state.products.map((p) => p.id),
    })
    if (!success) {
      alert('생성에 실패했습니다.')
      return
    }
    setState({ ...init, products: [...init.products] })

    setProductSets((prev) => [data.data, ...prev])
  }

  const updatePSet = async () => {
    if (!state.title || state.products.length === 0) {
      alert('product 최소 1개 이상 혹은 title 필수')
      return
    }

    await updateProductSet({
      productSetId: state.id,
      title: state.title,
      productIds: state.products.map((p) => p.id),
    })
    const updateSet = productSets.find((set) => set.id === state.id)
    updateSet.title = state.title
    setProductSets((prev) => [...prev])

    setState({ ...init })
  }

  const loadUpdate = async (id) => {
    const { data } = await findProductSet(id)
    setState({
      id: data.id,
      title: data.title,
      productIds: data.productIds,
      products: data.products,
    })
  }

  const storeItemOrder = async () => {
    const { success } = await setOrderNumberForProductSet({
      productSetIds: productSets.map((p) => p.id),
      orderNumbers: productSets.map((p) => p.orderNumber),
      statuses: productSets.map((p) => p.status),
    })
    if (success) {
      setOpen(true)
    } else {
      setOpenErr(true)
    }
  }

  const [open, setOpen] = useState(false)
  const [openErr, setOpenErr] = useState(false)

  return (
    <DefaultLayout isLoggedIn>
      {onRegister && (
        <RegisterProduct
          setOnRegister={setOnRegister}
          campaign={state}
          setCampaign={setState}
        />
      )}

      {!onRegister && (
        <>
          <CheckSnackbar open={open} setOpen={setOpen} icon={true} />
          <CheckSnackbar
            open={openErr}
            setOpen={setOpenErr}
            icon={true}
            color="red"
            message="기획전 순서가 정확히 입력되지 않았습니다."
          />
          <Box sx={boxStyle}>
            <Box display="flex">
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                기획전
              </p>
            </Box>

            <Divider sx={{ height: 16 }} />

            <OptionContainer>
              <LeftBox>
                <p>제목 :</p>
              </LeftBox>

              <InputTextField
                width="500px"
                label=""
                value={state.title}
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, title: e.target.value }))
                }}
              />
            </OptionContainer>

            <OptionContainer>
              <LeftBox>
                <p>최대할인율 :</p>
              </LeftBox>

              {state.products.length === 0 ? (
                <p>등록된 상품 할인율을 기준으로 자동계산됩니다.</p>
              ) : (
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {
                    state.products.sort((a, b) => {
                      return b.couponDiscountRateUS - a.couponDiscountRateUS
                    })[0].couponDiscountRateUS
                  }
                  %
                </p>
              )}
            </OptionContainer>

            <OptionContainer>
              <LeftBox ai="start">
                <p>상품 등록 :</p>
              </LeftBox>

              <Box display="flex" flexDirection="column" gap={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    gap: 3,
                    color: '#707070',
                  }}
                >
                  <Button
                    sx={{ marginLeft: '10px' }}
                    bg="white"
                    color="#707070"
                    border="1px solid #e4e4e4"
                    width={100}
                    height={40}
                    fs={14}
                    onClick={() => {
                      setOnRegister(true)
                    }}
                  >
                    + 상품추가
                  </Button>

                  <p>최대 10개</p>
                </Box>

                <DragDropItems
                  width={130}
                  height={130}
                  items={state.products}
                  setItems={(items) => {
                    setState({ ...state, products: items })
                  }}
                />
              </Box>
            </OptionContainer>

            <Box height={50} />

            <Box display="flex" gap={2}>
              <Button
                borderRadius="10px"
                bg="#FFF0F0"
                color="#F57979"
                onClick={() => {
                  setState({ ...init })
                }}
              >
                취소
              </Button>
              {state.id === null ? (
                <Button borderRadius="10px" onClick={createPSet}>
                  저장하기
                </Button>
              ) : (
                <Button borderRadius="10px" onClick={updatePSet}>
                  수정하기
                </Button>
              )}
            </Box>

            <Box width={1400}>
              <ProductSetListTable
                items={productSets}
                setItems={setProductSets}
                storeItems={storeItemOrder}
                loadUpdate={loadUpdate}
              />
              <Navigation
                page={option.page}
                count={option.count}
                setPage={(page) => {
                  setOption((prev) => ({ ...prev, page: page }))
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </DefaultLayout>
  )
}
export default ProductAdminPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

const LeftBox = ({ children, ai }) => (
  <Box
    width={100}
    display="flex"
    justifyContent={ai ? ai : 'center'}
    flexDirection="column"
    alignItems="end"
    marginRight={3}
    fontWeight={700}
    color="#707070"
  >
    {children}
  </Box>
)

const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)
